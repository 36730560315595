import React from 'react';
import Fade from 'react-reveal/Fade';
import { BiBullseye, BiTargetLock } from 'react-icons/bi';
import './about.css';
import aboutImg from './about.svg';

const About = () => (
  <section id="about" className="about">
    <div className="container">

      <div className="row">
        <Fade left>
          <div className="col-xl-6 col-lg-6 d-flex justify-content-center align-items-stretch">
            <img src={aboutImg} className="img-fluid" alt="cows grazing" />
          </div>
        </Fade>

        <Fade right>
          <div className="col-xl-6 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
            <h3>ABOUT US</h3>
            <p>
              We buy and sell livestock. To provide healthy and strong livestock
              High quality farming operations. We buy and sell livestock.
              To provide healthy and strong livestock
              High quality farming operations.
            </p>

            <div className="icon-box">
              <div className="icon"><BiTargetLock /></div>
              <h4 className="title">Mission</h4>
              <p className="description">To provide healthy and strong livestock and High quality farming operations</p>
            </div>

            <div className="icon-box">
              <div className="icon"><BiBullseye /></div>
              <h4 className="title">Vision</h4>
              <p className="description">
                High quality farming operations and To provide healthy and strong.
              </p>
            </div>
          </div>
        </Fade>

      </div>

    </div>
  </section>
);

export default About;
