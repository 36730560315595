/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './footer.css';

const Footer = () => (
  <footer id="footer">

    <div className="container py-4">
      <div className="copyright">
        &copy; Copyright
        {' '}
        <strong><span>African Farming Operations</span></strong>
        . All Rights Reserved
      </div>
      <div className="credits">
        Developed by
        {' '}
        <a href="https://yelah.co.za/"><strong>Yelah.</strong></a>
      </div>
    </div>
  </footer>
);

export default Footer;
