import React from 'react';
import Fade from 'react-reveal/Fade';
import { BiMap, BiEnvelope, BiPhone } from 'react-icons/bi';
import './contact.css';

const Contact = () => (
  <section id="contact" className="contact">
    <div className="container">

      <div className="section-title">
        <h2>Contact</h2>
      </div>

      <div className="info">
        <div className="row">
          <Fade left>
            <div className="col-lg-4">
              <div className="address">
                <div className="icon">
                  <BiMap />
                </div>

                <h4>Location:</h4>
                <p>A108 Adam Street, New York, NY 535022</p>
              </div>
            </div>
          </Fade>
          <Fade top>
            <div className="col-lg-4">
              <a href="mailto:info@example.com">
                <div className="email">

                  <div className="icon">
                    <BiEnvelope />
                  </div>
                  <h4>Email:</h4>
                  <p>info@example.com</p>

                </div>
              </a>
            </div>
          </Fade>
          <Fade right>
            <div className="col-lg-4">
              <a href="tel:+27123456789">
                <div className="phone">
                  <div className="icon">
                    <BiPhone />
                  </div>
                  <h4>Call:</h4>
                  <p>+1 5589 55488 55s</p>
                </div>
              </a>
            </div>
          </Fade>
        </div>
      </div>

    </div>
  </section>
);

export default Contact;
