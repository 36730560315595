/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Fade from 'react-reveal/Fade';
import './services.css';
import service1 from './service-1.svg';
import service2 from './service-2.svg';
import service3 from './service-3.svg';
import service4 from './service-4.svg';

const Services = () => (
  <section id="services" className="services section-bg">
    <div className="container">
      <Fade>
        <div className="section-title">
          <h2>services</h2>
          <p>
            We are Africa&apos;s top buyer and seller of livestock.
            We provide the following
            services with great love.
          </p>
        </div>
      </Fade>

      <div className="row content">
        <div className="col-md-5">
          <Fade left>
            <img src={service1} className="img-fluid" alt="..." />
          </Fade>
        </div>

        <div className="col-md-7 pt-4">
          <Fade right>
            <h3>Farming Operation 1</h3>
            <p className="font-italic">
              We are Africa&apos;s top buyer and seller of livestock.
              We provide the following
              services with great love.
            </p>
            <p>
              We buy and sell livestock. To provide healthy and strong livestock
              High quality farming operations. We buy and sell livestock.
              To provide healthy and strong livestock
              High quality farming operations.
            </p>
          </Fade>
        </div>
      </div>

      <div className="row content">
        <div className="col-md-5 order-1 order-md-2">
          <Fade right>
            <img src={service2} className="img-fluid" alt="..." />
          </Fade>
        </div>
        <div className="col-md-7 pt-5 order-2 order-md-1">
          <Fade left>
            <h3>Farming Operation 2</h3>
            <p className="font-italic">
              We are Africa&apos;s top buyer and seller of livestock.
              We provide the following
              services with great love.
            </p>
            <p>
              We buy and sell livestock. To provide healthy and strong livestock
              High quality farming operations. We buy and sell livestock.
              To provide healthy and strong livestock
              High quality farming operations.
            </p>
          </Fade>
        </div>
      </div>

      <div className="row content">
        <div className="col-md-5">
          <Fade left>
            <img src={service3} className="img-fluid" alt="..." />
          </Fade>
        </div>

        <div className="col-md-7 pt-4">
          <Fade right>
            <h3>Farming Operation 3</h3>
            <p className="font-italic">
              We are Africa&apos;s top buyer and seller of livestock.
              We provide the following
              services with great love.
            </p>
            <p>
              We buy and sell livestock. To provide healthy and strong livestock
              High quality farming operations. We buy and sell livestock.
              To provide healthy and strong livestock
              High quality farming operations.
            </p>
          </Fade>
        </div>
      </div>

      <div className="row content">
        <div className="col-md-5 order-1 order-md-2" data-aos="fade-left">
          <Fade right>
            <img src={service4} className="img-fluid" alt="..." />
          </Fade>
        </div>
        <div className="col-md-7 pt-5 order-2 order-md-1" data-aos="fade-right">
          <Fade left>
            <h3>Farming Operation 4</h3>
            <p className="font-italic">
              We are Africa&apos;s top buyer and seller of livestock.
              We provide the following
              services with great love.
            </p>
            <p>
              We buy and sell livestock. To provide healthy and strong livestock
              High quality farming operations. We buy and sell livestock.
              To provide healthy and strong livestock
              High quality farming operations.
            </p>
          </Fade>
        </div>
      </div>

    </div>
  </section>
);

export default Services;
