import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import './hero.css';
import heroImg from './hero-img.svg';

const Hero = () => (
  <section id="hero" className="d-flex align-items-center">

    <div className="container">
      <div className="row">
        <Fade top>
          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
            <h1>High quality farming operations</h1>
            <p>
              We buy and sell livestock. To provide healthy and strong livestock
              High quality farming operations. We buy and sell livestock.
            </p>
            <div>
              <Link
                to="about"
                smooth
                offset={-70}
                duration={750}
                className="btn-get-started"
              >
                Get Started
              </Link>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="col-lg-6 order-1 order-lg-2 hero-img">
            <img src={heroImg} className="img-fluid" alt="" />
          </div>
        </Fade>
      </div>
    </div>

  </section>
);

export default Hero;
