import React from 'react';
import Header from './Header/Header';
import Hero from './Hero/Hero';
import './App.css';
import About from './About/About';
import Services from './Services/Services';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';

const App = () => (
  <>
    <Header />
    <Hero />
    <main id="main">
      <About />
      <Services />
      <Contact />
    </main>
    <Footer />
  </>
);

export default App;
