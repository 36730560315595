import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link, animateScroll as scroll } from 'react-scroll';
import logo from './logo.png';
import './header.css';

const scrollTop = () => {
  scroll.scrollToTop();
};
const Header = () => (
  <header id="header" className="fixed-top">
    <Navbar collapseOnSelect expand="lg">
      <div className="container d-flex align-items-center justify-content-between">

        <Navbar.Brand href="#hero" className="logo" onClick={scrollTop}><img src={logo} alt="logo" className="img-fluid" width="60px" /></Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="nav-menu justify-content-end">
          <Nav defaultActiveKey="#hero" as="ul">
            <Nav.Item as="li">
              <Link
                activeClass="active"
                to="hero"
                spy
                smooth
                offset={-70}
                duration={750}
              >
                Home
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                activeClass="active"
                to="about"
                spy
                smooth
                offset={-70}
                duration={750}
              >
                About
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                activeClass="active"
                to="services"
                spy
                smooth
                offset={-70}
                duration={750}
              >
                Services
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                activeClass="active"
                to="contact"
                spy
                smooth
                offset={-70}
                duration={750}
              >
                Contact
              </Link>
            </Nav.Item>

          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>

  </header>
);

export default Header;
